<template>
  <div>
    <div class="detail-message">
      <div class="header d-flex">
        <b-icon
          class="header__icon"
          icon="chevron-left"
          variant="dark"
        ></b-icon>
        <div class="header__image">
          <img
            src="@/assets/image/system_avatar.svg"
            class="header__image__left"
          />
          <img src="@/assets/image/woman.png" class="header__image__right" />
        </div>
      </div>
      <div class="content">
        <div class="time">
          <p>10:15</p>
        </div>
        <div class="chat">
          <div class="chat__div chat__div--left">
            <div class="message message--last message--full message--system">
              現在、お申し込み内容での対応可能なキャストをお探ししています。
              <button @click="nextPage()" class="chat__button f-w3">
                詳細はこちら
              </button>
              <button @click="nextPage()" class="chat__button f-w3">
                オーダー状況
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <card-footer-body />
  </div>
</template>

<script>
import router from "@/router";
import CardFooter from "@/components/CardFooter";

export default {
  name: "SystemMessageConfirmOrder",
  components: {
    "card-footer-body": CardFooter
  },
  metaInfo() {
    return {
      title: "確認後のシステムメッセージ",
      titleTemplate: "%s | PreGo"
    };
  },
  methods: {
    nextPage() {
      router.push({ name: "#" });
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/men/systemMessage.scss";
</style>
